import * as Yup from 'yup';

// Login Validation
export const validationSchemaLogin = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Please enter email address"),
  password: Yup.string().required("Please enter password"),
});


// COI Validations
export const validationSchema = Yup.object().shape({
    englishCertificate: Yup.array().of(
      Yup.object().shape({
        selectedStates: Yup.array()
          .of(Yup.string().required('State selection is required'))
          .min(1, 'At least one state must be selected'),
        file: Yup.mixed(),
      })
    ),
  });
  
 export const validationSchemaSpanish = Yup.object().shape({
    spanishCertificate: Yup.array().of(
      Yup.object().shape({
        selectedStates: Yup.array()
          .of(Yup.string().required('State selection is required'))
          .min(1, 'At least one state must be selected'),
        file: Yup.mixed(),
      })
    ),
  });

// Products Validation
export const validationSchemaProducts = Yup.object().shape({
  product_name: Yup.string().required("Please enter product name"),
  product_code: Yup.string().required("Please enter product code"),
});

// Click to schedule care validation
export const validationSchemaScheduleCare = Yup.object().shape({
  contact_number: Yup.string().required("Please enter contact number"),
});

// Faq Validation 
export const validationSchemaFaq = Yup.object({
  questions: Yup.array().of(
    Yup.object({
      question: Yup.string().required("Please enter question"),
      answer: Yup.string().required("Please enter answer"),
    })
  ),
});

export const validationSchemaSpanishFaq = Yup.object({
  questionSpanish: Yup.array().of(
    Yup.object({
      question: Yup.string().required("Please enter question"),
      answer: Yup.string().required("Please enter answer"),
    })
  )
});

  
