import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./primecare.css";
import "./responsive.css";
import { ThemeProvider } from "./common/themeContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import { useEffect, useState } from "react";

const App = () => {
  // const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   const userAgent = navigator.userAgent;
  //   const mobileDeviceRegex = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i;

  //   setIsMobile(mobileDeviceRegex.test(userAgent));
  // }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <div className="mobileFont">
            {/* <div className={isMobile ? "mobileFont" : "d-none"}> */}
              <div className="max300">
              This website is not supported on Mobile web browsers.
              </div>
            </div>
            {/* <div className={isMobile ? "d-none" : ""}> */}
            <div className="windowScreen">
              <AppRoutes />
            </div>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};
export default App;
