import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import Loader from "../../common/loader";
import { addProductFeatureAction, addProductFeaturesHandler } from "../../redux/action/addProductFeaturesSlice";
import { BreadCrum } from "../../common";
import { fileUploadAction } from "../../redux/action/fileUpload";
import { getGroupProductFeatureContentHandler } from "../../redux/action/getGroupProductFeatureContent";
import { useCommonMessage } from "../../common/CommonMessage";


const DigitizedIdCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { state } = location
  const messageApi = useCommonMessage();

  const getProductId = localStorage.getItem("productId")
  const [formData, setFormData] = useState({
    select_company: '',
    companyUrl: ''
  });

  const getProductFeatureSelector = useSelector((state) => state?.getProductFeatureContentMain)
  const getGroupProductFeatureContent = useSelector((state) => state?.getGroupProductFeatureContent)
  const addProductFeatures = useSelector((state) => state?.addProductFeatures)
  let getFeatureFromGroup = getGroupProductFeatureContent?.data?.data

  const handleChange = (value) => {
    const selectedCompany = getProductFeatureSelector?.data?.data?.content?.company?.find(item => item.name === value);

    setFormData({
      ...formData,
      select_company: value,
      companyUrl: selectedCompany?.url || ''
    });
  };

  const handleFormSubmit = (values) => {
    const { select_company, companyUrl } = formData;
    let payload = {
      content: {
        name: select_company || getProductFeatureSelector?.data?.data?.content?.name,
        url: companyUrl || getProductFeatureSelector?.data?.data?.content?.url,
        redirection_url: true,
      },
      productId: getProductFeatureSelector?.data?.data?.productId,
      featureId: getProductFeatureSelector?.data?.data?.refFeatureId
    }
    dispatch(addProductFeaturesHandler(payload))
  }

  useEffect(() => {
    if (state?.fromGroupData?.id) {
      let payload1 = {
        groupProductFeatureId: state?.fromGroupData?.id
      }
      dispatch(getGroupProductFeatureContentHandler(payload1))
    } else {
      let payload = {
        productId: state?.productId || state?.item?.productId,
        refFeatureId: state?.refFeatureId || state?.item?.refFeatureId
      }
      dispatch(getProductFeatureContentHandler(payload))
    }
  }, [state])


  useEffect(() => {
    if (addProductFeatures?.data) {
      messageApi.open({
        type: 'success',
        content: addProductFeatures?.data?.message,
      });
      setTimeout(() => {
        navigate("/addproduct")
        dispatch(addProductFeatureAction.addProductFeaturesInfoReset())
      }, 1000);
    }
  }, [addProductFeatures])


  return (
    <>
      {(getProductFeatureSelector?.isLoading === true || addProductFeatures?.isLoading === true) && <Loader loaderTransForm="loaderTransform" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: getFeatureFromGroup?.groupLevelModification ? BreadCrum.GROUPS : BreadCrum.PRODUCTS, onClick: () => { navigate(getFeatureFromGroup?.groupLevelModification ? "/groups" : "/dashboard"); localStorage.removeItem("productId"); dispatch(fileUploadAction.fileUploadInfoReset()) } },
              {
                text: state?.manageProduct || getFeatureFromGroup?.groupLevelModification ? BreadCrum.MANAGE_PRODUCTS : BreadCrum.ADD_PRODUCT,
                onClick: () => {
                  const path = state?.manageProduct
                    ? "/edit-product"
                    : getFeatureFromGroup?.groupLevelModification
                      ? "/edit-group-product"
                      : "/addproduct";
                  const navigationState = {
                    productId: state?.productId || Number(getProductId),
                    groupCode: state?.fromGroupData?.groupId
                  };
                  dispatch(fileUploadAction.fileUploadInfoReset())
                  navigate(path, { state: navigationState });
                }
              },
              ...(getFeatureFromGroup?.groupLevelModification && getFeatureFromGroup?.productName ? [{ text: getFeatureFromGroup.productName }] : []),
              { text: BreadCrum.DISCLAIMERS, active: true },
            ]}
          />
          <Formik
            enableReinitialize
            initialValues={{
              select_company: getProductFeatureSelector?.data?.data?.content?.name || "",
            }}
            onSubmit={(values, formikBag) => {
              handleFormSubmit(values, formikBag);
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="login-form">
                <div className="table-padding mb-30">
                  <div className="headtitle d-block antselect">
                    <p className="mb-3 heedprodct">Digitized ID card</p>
                    <hr />
                    <div className="">
                      <div className="pe-2 position-relative">
                        <label className="mb-3">Select Company</label>
                        <Select className="form-select p-0 w-50"
                          placeholder="Select Company"
                          value={values.select_company || formData.select_company}
                          style={{
                            width: 120,
                          }}
                          onChange={handleChange}
                          options={getProductFeatureSelector?.data?.data?.content?.company?.map(
                            (item) => ({
                              value: item?.name,
                              label: item?.name,
                              url: item?.url
                            })
                          )}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="text-end">
                        <button
                          type="submit"
                          className=" sbg border-0 py3 fw-18 gbo continuebtn  savebtn"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default DigitizedIdCard;
