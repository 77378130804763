import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "../action/loginSlice"
import getAllProductListSlice from "../action/getAllProductSlice"
import getAllUserListSlice from "../action/getAllUserListSlice"
import getUserDetailsSlice from "../action/getUserDetailsSlice"
import getAllGroupsSlice from "../action/getAllGroups"
import getAllGroupsMembersSlice from "../action/getGroupsMembers"
import getAllProductsInGroupsSlice from "../action/getAllProductsInGroups"
import addProductSlice from "../action/addProductSlice"
import getProductFeaturesSlice from "../action/getproductFeaturesSlice"
import addProductFeaturesSlice from "../action/addProductFeaturesSlice"
import getProductFeatureContentSlice from "../action/getProductFeatureContentSlice"
import fileUploadSlice from "../action/fileUpload"
import updateProduct from "../action/updateProduct-Slice"
import getAllStatesSlice from "../action/getAllStates"
import getGroupProductFeatures from "../action/getGroupProductFeature"
import updateGroupProductFeatures from "../action/updateGroupProductFeature"
import getGroupProductFeatureContent from "../action/getGroupProductFeatureContent"
import addNotificationDetailsDetails from "../action/addNotificationsSlice"
import updateNotificationsDetails from "../action/updateNotificationsSlice"
import getNotificationsListSlice from "../action/getNotificationsListSlice";
import deleteNotification from "../action/deleteNotification";
import reportsList from "../action/reportsSlice"

const store = configureStore({
    reducer: {
        loginSliceDetails: loginSlice,
        getAllProductList: getAllProductListSlice,
        getAllUserList: getAllUserListSlice,
        getUserDetails: getUserDetailsSlice,
        getAllGroups: getAllGroupsSlice,
        getAllGroupsMemberList: getAllGroupsMembersSlice,
        getAllProductInGroupsList: getAllProductsInGroupsSlice,
        addProductDetails: addProductSlice,
        getProductFeatures: getProductFeaturesSlice,
        addProductFeatures: addProductFeaturesSlice,
        getProductFeatureContentMain: getProductFeatureContentSlice,
        fileUploadData: fileUploadSlice,
        updateProductSlice: updateProduct,
        getAllStates: getAllStatesSlice,
        getGroupProductFeatureState: getGroupProductFeatures,
        updateGroupProductFeatureMain: updateGroupProductFeatures,
        getGroupProductFeatureContent: getGroupProductFeatureContent,
        notificationSliceDetails: addNotificationDetailsDetails,
        updateNotificationsSlice: updateNotificationsDetails,
        getNotificationsListDetails: getNotificationsListSlice,
        deleteNotificationSlice: deleteNotification,
        reportsListDetails:reportsList
    },
});

export default store;
