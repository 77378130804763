import { createSlice } from "@reduxjs/toolkit";
import { getGroupProductFeatureAPI } from "../../services/getGroupProductFeatures";

const data = {
    isLoading: false,
    error: "",
    message: "",
    data: null,
};

const getGroupProductFeatureSlice = createSlice({
    name: "getGroupProductFeatureState",
    initialState: data,
    reducers: {
        getGroupProductFeatureInfo(state) {
            state.isLoading = true;
        },
        getGroupProductFeatureSuccess(state, action) {
            state.isLoading = false;
            state.data = action.payload;
            state.message = "";
        },
        getGroupProductFeatureFailure(state, action) {
            state.isLoading = false;
            state.message = action.payload;
            state.data = null;
        },
        getGroupProductFeatureReset(state) {
            state.isLoading = false;
            state.message = "";
            state.data = null;
            state.error = "";
        },
    },
});

export const getGroupProductFeatureHandler = (data) => async (dispatch) => {
    try {
        dispatch(getGroupProductFeatureAction.getGroupProductFeatureInfo());
        const response = await getGroupProductFeatureAPI(data);
        dispatch(getGroupProductFeatureAction.getGroupProductFeatureSuccess(response));
    } catch (e) {
        dispatch(getGroupProductFeatureAction.getGroupProductFeatureFailure(e?.response?.data?.message));
    }
};
export default getGroupProductFeatureSlice.reducer;
export const getGroupProductFeatureAction = getGroupProductFeatureSlice.actions;
