import { createSlice } from "@reduxjs/toolkit";
import { getGroupProductFeatureContentAPI } from "../../services/getGroupProductFeatureContent";

const data = {
    isLoading: false,
    error: "",
    message: "",
    data: null,
};

const getGroupProductFeatureContentSlice = createSlice({
    name: "getGroupProductFeatureContent",
    initialState: data,
    reducers: {
        getGroupProductFeatureContentInfo(state) {
            state.isLoading = true;
        },
        getGroupProductFeatureContentSuccess(state, action) {
            state.isLoading = false;
            state.data = action.payload;
            state.message = "";
        },
        getGroupProductFeatureContentFailure(state, action) {
            state.isLoading = false;
            state.message = action.payload;
            state.data = null;
        },
        getGroupProductFeatureContentReset(state) {
            state.isLoading = false;
            state.message = "";
            state.data = null;
            state.error = "";
        },
    },
});

export const getGroupProductFeatureContentHandler = (data) => async (dispatch) => {
    try {
        dispatch(getGroupProductFeatureContentAction.getGroupProductFeatureContentInfo());
        const response = await getGroupProductFeatureContentAPI(data);
        dispatch(getGroupProductFeatureContentAction.getGroupProductFeatureContentSuccess(response));
    } catch (e) {
        dispatch(getGroupProductFeatureContentAction.getGroupProductFeatureContentFailure(e?.response?.data?.message));
    }
};
export default getGroupProductFeatureContentSlice.reducer;
export const getGroupProductFeatureContentAction = getGroupProductFeatureContentSlice.actions;
