import { createSlice } from "@reduxjs/toolkit";
import { addNotificationsAPI } from "../../services/addNotifications";

const data = {
    isLoading: false,
    error: "",
    message: "",
    data: null,
};

const notificationsDetailsSlice = createSlice({
    name: "notificationSliceDetails",
    initialState: data,
    reducers: {
        notificationsDetailsSliceInfo(state) {
            state.isLoading = true;
        },
        notificationsDetailsSliceSuccess(state, action) {
            state.isLoading = false;
            state.data = action.payload;
            state.message = "";
        },
        notificationsDetailsSliceFailure(state, action) {
            state.isLoading = false;
            state.message = action.payload;
            state.data = null;
        },
        notificationsDetailsSliceReset(state) {
            state.isLoading = false;
            state.message = "";
            state.data = null;
            state.error = "";
        },
    },
});

export const notificationHandler = (data) => async (dispatch) => {
    try {
        dispatch(notificationsSliceAction.notificationsDetailsSliceInfo());
        const response = await addNotificationsAPI(data);
        dispatch(notificationsSliceAction.notificationsDetailsSliceSuccess(response));
    } catch (e) {
        dispatch(notificationsSliceAction.notificationsDetailsSliceFailure(e));
    }
};
export default notificationsDetailsSlice.reducer;
export const notificationsSliceAction = notificationsDetailsSlice.actions;
