import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dashboardColumns } from "./dashboardUtils";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import CommonTable from "../../common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/loader"
import { getProductHandler } from "../../redux/action/getAllProductSlice";
import { ThemeContext } from "../../common/themeContext";
import { addProductAction } from "../../redux/action/addProductSlice";
import { getProductFeaturesAction } from "../../redux/action/getproductFeaturesSlice";
// import { CommanSearchBox } from "../../common/CommanSearchBox";

const Dashboard = () => {
  const [getProduct, setGetProduct] = useState([])
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 100,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 15, 20],
    className: "order-pagination",
    locale: { items_per_page: "" },
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total}`,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const dashBoardColumnsData = dashboardColumns(navigate)


  const getAllProductList = useSelector((state) => state?.getAllProductList)

  const { brandId, isLoading } = useContext(ThemeContext);

  const handleProductData = (offSet = 1, limit = 10) => {
    window.scrollTo(0, 0);
    const payload = { offSet, limit, refBrandId: brandId };
    // if (searchString) {
    //   payload["searchString"] = searchString;
    // }
    if (brandId === 1 || brandId === 2) {
      dispatch(getProductHandler(payload));
    }
  }

  useEffect(() => {
    handleProductData()
  }, [brandId]);

  useEffect(() => {
    if (getAllProductList?.data?.data) {
      const productData = getAllProductList?.data?.data?.map(item => ({
        productId: item?.id,
        productName: item?.name,
        productStatus: item?.activeStatus.charAt(0).toUpperCase() + item?.activeStatus.slice(1).toLowerCase(),
        productCode: item?.code,
      }))
      setGetProduct(productData);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: getAllProductList?.data?.totalElements,
        current: getAllProductList?.data?.pageNumber,
        pageSize: getAllProductList?.data?.pageSize,
      }));
    }
  }, [getAllProductList]);

  const addProduct = () => {
    navigate('/addproduct')
    dispatch(addProductAction.addProductSliceInfoReset());
    dispatch(getProductFeaturesAction.getProductFeaturesInfoReset())
    localStorage.removeItem("productId")
  }

  return (
    <>
      {(getAllProductList?.isLoading === true || isLoading === true) && <Loader loaderTransform="loaderTransform" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: "Home" },
              { text: "Products", active: true },
            ]}
          />

          <div className="table-padding">
            <div className="headtitle">
              <p className="mb-0 heedprodct">Products</p>
              {/* <CommanSearchBox
                value={searchValue}
                setValue={setSearchValue}
                getSearchValue={handleProductData}
                placeholder={"Search By Product Name"}
              /> */}
              <button className="primarybtn" onClick={addProduct}>
                <div className="plus"></div>
                Add
              </button>
            </div>
            <hr className="m-0" />
            <div className="table-responsive">
              <CommonTable
                handlePageChange={handleProductData}
                pagination={pagination}
                body={getProduct}
                columns={dashBoardColumnsData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
