import React from 'react';
import { Select } from 'antd';

const SelectInput = ({ options, placeholder, onChange, mode, value, defaultValue, onClear, onSelect, onDeselect, isDisabled, id, onBlur, name, ...rest }) => {
  const handleChange = (selectedProductName) => {
    onChange(selectedProductName);
  };
  return (
    <>
      <Select
        name={name}
        className="form-select p-0 w-100"
        placeholder={placeholder}
        style={{
          width: 320,
        }}
        onSelect={onSelect}
        options={options}
        id={id}
        onChange={handleChange}
        onBlur={onBlur}
        onDeselect={onDeselect}
        onClear={onClear}
        mode={mode}
        value={value}
        defaultValue={defaultValue}
        disabled={isDisabled}
        {...rest}
      />
    </>
  );
};

export default SelectInput;
