import { render } from "@testing-library/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const claimsListColumns = (navigate) => [
  {
    title: "Claim Name",
    dataIndex: "claimName",
    key: "claimName",
    sorter: {
      compare: (a, b) => a.claimName.localeCompare(b.claimName),
    },
  },
  {
    title: "Claim type",
    dataIndex: "claimType",
    key: "claimType",
    sorter: {
      compare: (a, b) => a.claimType.localeCompare(b.claimType),
    },
    render: (key) => {
      return (
        <div>{key === "url" && "Redirection Url" || key === "form" && "Form" || key === "quiz" && "Quiz" ||key==="offlineClaim"&&"Offline Claim"}</div>
      );
    },
  },
  {
    title: "Language",
    dataIndex: "language",
    // sorter: {
    //   compare: (a, b) => a.language.localeCompare(b.language),
    // },
  },

  {
    title: "Claim Status",
    dataIndex: "claimStatus",
    key: "claimStatus",
    sorter: {
      compare: (a, b) => a.claimStatus.localeCompare(b.claimStatus),
      multiple: 3,
    },
    render: (key) => {
      return (
        <div className={key === "Active" ? "activted" : "inactive"}>{key}</div>
      );
    },
  },

  {
    title: "Action",
    // key: "action",
    render: (key) => {
      return (
        <div className="action-icons">
          <div>
            <button
              className="manage"
              onClick={() => {
                navigate("/Add-claim", { state: key });
                localStorage.setItem("productId", key?.productId);
                // localStorage.setItem("productName", key);
              }}
            >
              Manage
            </button>
          </div>
        </div>
      );
    },
  },
];
