
import React, { createContext, useEffect, useState } from 'react';
import Loader from './loader';
import { useNavigate } from 'react-router-dom';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [brandId,setBrandId] = useState(null)
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme||"healthCare";
  });
const navigate = useNavigate()
  const toggleTheme = () => {
    setIsLoading(true);
    navigate("/dashboard")
    setTheme(prevTheme => prevTheme === 'healthCare' ? 'primeCare' : 'healthCare');
  };

//  Set Brand Id as per change brand Like healthCare and Primecare
  useEffect(() => {
    localStorage.setItem('theme', theme);
    const refBrandId = theme === 'healthCare' ? 1 : 2;
    localStorage.setItem('refBrandId', refBrandId);
    setBrandId(refBrandId)
    setTimeout(() => {
      setIsLoading(false); 
    }, 2000); 
  }, [theme]);

  return (
    <>
    {isLoading && <Loader loaderTransform="loaderTransform"/>}
    <ThemeContext.Provider value={{ theme, toggleTheme,brandId,isLoading }}>
      {children}
    </ThemeContext.Provider>
    </>
  );
};