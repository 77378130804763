import { createSlice } from "@reduxjs/toolkit";
import { updateGroupProductFeatureAPI } from "../../services/updateGroupProductFeature";

const data = {
    isLoading: false,
    error: "",
    message: "",
    data: null,
};

const updateGroupProductFeatureSlice = createSlice({
    name: "updateGroupProductFeatureMain",
    initialState: data,
    reducers: {
        updateGroupProductFeatureInfo(state) {
            state.isLoading = true;
        },
        updateGroupProductFeatureInfoSuccess(state, action) {
            state.isLoading = false;
            state.data = action.payload;
            state.message = "";
        },
        updateGroupProductFeatureInfoFailure(state, action) {
            state.isLoading = false;
            state.message = action.payload;
            state.data = null;
        },
        updateGroupProductFeatureInfoReset(state) {
            state.isLoading = false;
            state.message = "";
            state.data = null;
            state.error = "";
        },
    },
});

export const updateGroupProductFeaturesHandler = (data) => async (dispatch) => {
    try {
        dispatch(updateGroupProductFeatureAction.updateGroupProductFeatureInfo());
        const response = await updateGroupProductFeatureAPI(data);
        dispatch(updateGroupProductFeatureAction.updateGroupProductFeatureInfoSuccess(response));
    } catch (e) {
        dispatch(updateGroupProductFeatureAction.updateGroupProductFeatureInfoFailure(e));
    }
};
export default updateGroupProductFeatureSlice.reducer;
export const updateGroupProductFeatureAction = updateGroupProductFeatureSlice.actions;
