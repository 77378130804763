import React, { useState } from 'react';
import arrowright from '../../assets/Arrow_right.svg';
import Form from 'react-bootstrap/Form';
import { Checkbox } from 'antd';
import radioimg from '../../assets/radio.svg';
import checkimg from '../../assets/checkbox.svg';
import dropimg from '../../assets/dropdown.svg';
import textimg from '../../assets/textbox.svg';
import ansbtn from '../../assets/ansbtn.svg';
import addbtn from '../../assets/Add_Button.svg';
import copybtn from '../../assets/Copy.svg';
import Bold from '../../assets/Bold.svg';
import Italic from '../../assets/Italic.svg';
import Underline from '../../assets/Underline.svg';
import RemoveFormatting from '../../assets/RemoveFormatting.svg';
import Addlink from '../../assets/Addlink.svg';
import deletebtn from '../../assets/Delete.svg';
import deleteblue from '../../assets/DeleteBlue.svg';
import { Link, useNavigate } from 'react-router-dom';
import SelectInput from '../../common/SelectInput';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';

const ClaimManage = () => {
  const navigate = useNavigate();
  const [manageSwitch, setManageSwitch] = useState(false);
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div className="rightside">
      <div className="rightcontent">
        <p>
          <strong>Claims</strong>
        </p>
      
        <CustomBreadcrumb
            items={[
              { text: "Home" },
              { text: "Products", onClick: () => navigate("/dashboard") },
              { text: "Manage Product", onClick: () => navigate("/edit-product") },
              { text: "Claim", active: true },
            ]}
          />
        <Form className="login-form ">
          <div className="table-padding mb-30">
            <div className="headtitle d-block">
              <div className="d-flex justify-content-between align-items-center d-4grid">
                <div>
                  <div className="productname mb-10">Claim Name</div>
                  <div className="producttitle ">DPCP</div>
                </div>
                <div className="verticalline mx-100"></div>
                <div className="">
                  <div className="productname mb-10">No of Questions</div>
                  <div className="producttitle ">10</div>
                </div>
                <div className="verticalline mx-100"></div>
                <div className="">
                  <div className="productname mb-10">No of Questions</div>
                  <div className="producttitle ">10</div>
                </div>
                <div className="verticalline mx-100"></div>
                <div className="text-end">
                  <button className="primarybtn ms-md-auto me-5 disabledbtn">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="switches-container mb-30">
              <div className="mempro" onClick={() => setManageSwitch(false)}>
                English
              </div>
              <div className="mempro" onClick={() => setManageSwitch(true)}>
                Spanish
              </div>
              <div
                className={
                  !manageSwitch ? "switch-wrapper" : "switch-wrapper switchside"
                }
              >
                <div className="switch">
                  {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
                </div>
              </div>
            </div>
          <div className="sectionpadding mb-30">
            <p className="mb-3 heedprodct activetext">Quiz Title</p>
            <p className="mb-0">This will contain description about the quiz</p>
          </div>
          <div className="sectionpadding mb-30 d-flex align-items-center justify-content-between">
            <div>
              <p className="mb-3 heedprodct activetext">Set 1 Title</p>
              <p className="mb-0">
                This will contain description about Set 1 Title
              </p>
            </div>
            <div>
              <img src={arrowright} alt="" />
            </div>
          </div>
          <div className="sectionpadding mb-30 d-flex align-items-center justify-content-between">
            <div>
              <p className="mb-3 heedprodct activetext">Set 2 Title</p>
              <p className="mb-0">
                This will contain description about Set 2 Title
              </p>
            </div>
            <div>
              <img src={arrowright} alt="" />
            </div>
          </div>

          <div className="sectionpadding mb-30 d-flex align-items-center justify-content-between">
            <div>
              <p className="mb-3 heedprodct activetext">Quiz Title</p>
              <p className="mb-0">
                This will contain description about the quiz
              </p>
            </div>
            <div>
              <button className="primarybtn">Edit</button>
            </div>
          </div>
          <div className="sectionpadding editmodeon mb-30 ">
            <div className="d-xxl-flex mb-30">
              <Form.Group
                className=" pe-xxl-2 position-relative selecttype me-xxl-2 mb-3 mb-xxl-0"
                controlId="formBasicEmail"
              >
                <Form.Label>Select Question Type</Form.Label>
                <SelectInput
                  options={[
                    {
                      value: 'Multiple choice',
                      label: (
                        <div className="d-flex align-items-center">
                          <img src={radioimg} className="me-2" alt="" />
                          <span>Multiple Choice</span>
                        </div>
                      ),
                    },
                    {
                      value: 'Checkboxes',
                      label: (
                        <div className="d-flex align-items-center">
                          <img src={checkimg} className="me-2" alt="" />
                          <span>Checkboxes</span>
                        </div>
                      ),
                    },
                    {
                      value: 'Dropdown',
                      label: (
                        <div className="d-flex align-items-center">
                          <img src={dropimg} className="me-2" alt="" />
                          <span>Dropdown</span>
                        </div>
                      ),
                    },
                    {
                      value: 'Textbox',
                      label: (
                        <div className="d-flex align-items-center">
                          <img src={textimg} className="me-2" alt="" />
                          <span>Text Box</span>
                        </div>
                      ),
                    },
                  ]}
                  placeholder={'Select Question Type'}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className=" pe-xxl-2 position-relative me-xxl-2 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label>No. of Choices</Form.Label>
                <SelectInput
                  options={[
                    {
                      value: ' 1',
                      label: ' 1',
                    },
                    {
                      value: ' 3',
                      label: ' 3',
                    },
                    {
                      value: ' 2',
                      label: ' 2',
                    },
                  ]}
                  placeholder={'1'}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className=" pe-xxl-2 position-relative selecttype"
                controlId="formBasicEmail"
              >
                <Form.Label>Enumeration</Form.Label>
                <SelectInput
                  options={[
                    {
                      value: 'alpha',
                      label: 'A,B,C,D',
                    },
                    {
                      value: 'numeric',
                      label: '1,2,3,4',
                    },
                    {
                      value: 'ALPHA',
                      label: 'a,b,c,d',
                    },
                  ]}
                  placeholder={'Select Question Type'}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <Form.Group
              className=" pe-2 position-relative mb-30"
              controlId="formBasicEmail"
            >
              <Form.Label>Question </Form.Label>
              <Form.Control
                className="mb-10 selectdisplayed"
                type="text"
                placeholder="Untitled Question"
              />
            </Form.Group>

            <Form.Group
              className=" pe-2 position-relative optionselection"
              controlId="formBasicEmail"
            >
              <Form.Label className="mb-20">Options </Form.Label>
              <ul className="p-0">
                <li>
                  <Checkbox>
                    <div className="checkradiobtn d-inline-block"></div>
                    <div className="d-flex align-items-center justify-content-between w-cal">
                      <input type="text" placeholder="Option 01" />
                      <img src={deleteblue} alt="" />
                    </div>
                  </Checkbox>
                </li>
                <li>
                  <Checkbox>
                    <div className="checkradiobtn d-inline-block"></div>
                    <div className="d-flex align-items-center justify-content-between w-cal">
                      <input type="text" placeholder="Option 02" />
                      <img src={deleteblue} alt="" />
                    </div>
                  </Checkbox>
                </li>
                <li>
                  <Checkbox>
                    <div className="checkradiobtn d-inline-block"></div>
                    <div className="d-flex align-items-center justify-content-between w-cal">
                      <input type="text" placeholder="Option 03" />
                      <img src={deleteblue} alt="" />
                    </div>
                  </Checkbox>
                </li>
                <li>
                  <Checkbox>
                    <div className="checkradiobtn d-inline-block"></div>
                    <div className="d-flex align-items-center justify-content-between w-cal">
                      <input type="text" placeholder="Option 04" />
                      <img src={deleteblue} alt="" />
                    </div>
                  </Checkbox>
                </li>
              </ul>
            </Form.Group>
          </div>
          <div>
            <div className="sectionpadding mb-30 hoversection">
              <div className="d-xxl-flex mb-30">
                <Form.Group
                  className=" pe-xxl-2 position-relative selecttype me-xxl-2 mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Select Question Type</Form.Label>
                  <SelectInput
                    options={[
                      {
                        value: 'Multiple choice',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={radioimg} className="me-2" alt="" />
                            <span>Multiple Choice</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Checkboxes',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={checkimg} className="me-2" alt="" />
                            <span>Checkboxes</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Dropdown',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={dropimg} className="me-2" alt="" />
                            <span>Dropdown</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Textbox',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={textimg} className="me-2" alt="" />
                            <span>Text Box</span>
                          </div>
                        ),
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className=" pe-xxl-2 position-relative me-xxl-2 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>No. of Choices</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: ' 1',
                        label: ' 1',
                      },
                      {
                        value: ' 3',
                        label: ' 3',
                      },
                      {
                        value: ' 2',
                        label: ' 2',
                      },
                    ]}
                    placeholder={'1'}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className=" pe-xxl-2 position-relative selecttype"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Enumeration</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: 'alpha',
                        label: 'A,B,C,D',
                      },
                      {
                        value: 'numeric',
                        label: '1,2,3,4',
                      },
                      {
                        value: 'ALPHA',
                        label: 'a,b,c,d',
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <Form.Group
                className=" pe-2 position-relative mb-30"
                controlId="formBasicEmail"
              >
                <Form.Label>Question </Form.Label>
                <Form.Control
                  className="mb-10 selectdisplayed"
                  type="text"
                  placeholder="Untitled Question"
                />
                <div className="selectdisplay">
                  <button>
                    <img src={Bold} alt="" />
                  </button>
                  <button>
                    <img src={Italic} alt="" />
                  </button>
                  <button>
                    <img src={Underline} alt="" />
                  </button>
                  <button>
                    <img src={Addlink} alt="" />
                  </button>
                  <button>
                    <img src={RemoveFormatting} alt="" />
                  </button>
                </div>
              </Form.Group>

              <Form.Group
                className=" pe-2 position-relative optionselection"
                controlId="formBasicEmail"
              >
                <Form.Label className="mb-20">Options </Form.Label>
                <ul className="p-0">
                  <li>
                    <Checkbox className="ansSelected">
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 01" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 02" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 03" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 04" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                </ul>
              </Form.Group>
              <hr className="mb-2" />
              <div className="w-100 d-flex justify-content-between iconsizeresponsive">
                <div className=" d-flex align-items-center">
                  <Link className="plusicon anskey">
                    <img src={ansbtn} alt="" /> <span>Answer key</span>
                  </Link>
                  <div className="optionselected">(Option 01)</div>
                </div>
                <div className=" d-flex align-items-center">
                  <Link className="me-20">
                    <img src={addbtn} alt="" />
                  </Link>
                  <Link className="me-20">
                    <img src={copybtn} alt="" />
                  </Link>
                  <Link className="">
                    <img src={deletebtn} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ClaimManage;
