import moment from "moment";



export const notificationColumns = (navigate) => [
  {
    title: 'Notification Title',
    key: "title",
    sorter: {
      compare: (a, b) => a.title - b.title,
      multiple: 3,
    },
    render: (record) => {
      let title = '';

      if (record.notificationConfigJson?.sms) {
        title = 'SMS';
      } else if (record.notificationConfigJson?.email) {
        title = record?.emailSubject;
      } else if (record?.notificationConfigJson?.pushNotification) {
        title = record?.pushNotificationTitle;
      }

      return <span>{title}</span>;
    },
  },
  {
    title: 'Creation Date',
    dataIndex: 'createDate',
    key: "createDate",
    sorter: {
      compare: (a, b) => a.createDate.localeCompare(b.createDate),
    },
    render: (date) => {
      if (date) {
        const dt = moment(date);
        const formattedDate = dt.format('YYYY-MM-DD');

        return formattedDate;
      }
    },
  },
  {
    title: 'Sent Date',
    dataIndex: 'scheduledDate',
    key: "scheduledDate",
    sorter: {
      compare: (a, b) => a.scheduledDate.localeCompare(b.scheduledDate),
    },
    render: (date) => {
      if (date) {
        const dt = moment(date);
        const formattedDate = dt.format('YYYY-MM-DD');

        return formattedDate;
      }
    },
  },
  {
    title: 'Notification Type',
    dataIndex: 'notificationConfigJson',
    key: "notificationConfigJson",
    render: (data) => {
      const trueElement = Object.keys(data).find((key) => data[key] === true);
      let displayText = '';

      if (trueElement === 'sms') {
        displayText = 'SMS';
      } else if (trueElement === 'email') {
        displayText = 'Email';
      } else if (trueElement === 'pushNotification') {
        displayText = 'Push Notifications';
      }

      return displayText && <p>{displayText}</p>;
    },
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: "language",
    sorter: {
      compare: (a, b) => a.language - b.language,
      multiple: 1,
    },
    render: (text) => {
      return (
        <div className={text === 'en' ? 'English' : 'Spanish'}>{text}</div>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'deliveryStatus',
    key: "deliveryStatus",
    sorter: {
      compare: (a, b) => a.deliveryStatus - b.deliveryStatus,
      multiple: 1,
    },
    render: (text) => {
      return (
        <div className={text === 'Sent' ? 'sent' : 'schedule'}>{text}</div>
      );
    },
  },
  {
    title: 'Action',
    render: (key) => {
      return (
        <div className="action-icons">
          <div>
            <button
              className="manage"
              onClick={() => {
                navigate('/edit-notification', { state: key });
              }}
            >
              Manage
            </button>
          </div>
        </div>
      );
    },
  },
];