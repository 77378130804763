import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsMembersHandler } from "../../redux/action/getGroupsMembers";
import Loader from "../../common/loader"
import { membersColumns, manageGroupProductColumns } from "./groupUtils";
import { getAllProductsInGroupsHandler } from "../../redux/action/getAllProductsInGroups";
import { ThemeContext } from "../../common/themeContext";
import CommonTable from "../../common/CommonTable";
import { BreadCrum } from "../../common";
import { CommanSearchBox } from "../../common/commanSearchBox";

const ManageMember = () => {
  const [manageSwitch, setManageSwitch] = useState(false);
  const [showProductData, setShowProductData] = useState([])
  const [showMembersData, setShowMembersData] = useState([])
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 100,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 15, 20],
    className: "order-pagination",
    locale: { items_per_page: "" },
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total}`,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { brandId } = useContext(ThemeContext);
  const columns = manageGroupProductColumns(navigate);

  const memberSelector = useSelector((state) => state?.getAllGroupsMemberList)
  const getAllProductInGroups = useSelector((state) => state?.getAllProductInGroupsList)
  const groupId = localStorage.getItem("groupId")

  const handleMemberListing = (offSet = 1, limit = 10, searchString = searchValue) => {
    window.scrollTo(0, 0);
    const payload = { offSet, limit, brandId: brandId, groupId: state?.groupId || Number(groupId) || state?.fromEditGroup?.groupCode };
    if (searchString) {
      payload["searchString"] = searchString;
    }
    if (brandId === 1 || brandId === 2) {
      dispatch(getGroupsMembersHandler(payload));
    }
  }

  const handleProductListing = (offSet = 1, limit = 10) => {
    window.scrollTo(0, 0);
    const payload = { offSet, limit, brandId: brandId, groupId: state?.groupId || Number(groupId) || state?.fromEditGroup?.groupCode, };
    if (brandId === 1 || brandId === 2) {
      dispatch(getAllProductsInGroupsHandler(payload));
    }
  }

  useEffect(() => {
    handleMemberListing()
    handleProductListing()
  }, [brandId]);


  // Get All products in groups
  useEffect(() => {
    if (getAllProductInGroups?.data?.data?.groupProducts) {
      const data = getAllProductInGroups?.data?.data?.groupProducts?.map((item) => {
        return {
          productName: item?.name,
          productCode: item?.code,
          productStatus: item?.activeStatus.charAt(0).toUpperCase() + item?.activeStatus.slice(1).toLowerCase(),
          productId: item?.productId,
          groupCode: state?.groupCode
        }
      })
      setShowProductData(data)
    }
  }, [getAllProductInGroups])

  // Get All Members In Groups

  useEffect(() => {
    if (memberSelector?.data?.data?.users) {
      const data = memberSelector?.data?.data?.users?.map((item) => {
        return {
          memberCode: item?.caspioUserId,
          memberName: item?.firstName + " " + item?.lastName,
        }
      })
      setShowMembersData(data)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: memberSelector?.data?.totalElements,
        current: memberSelector?.data?.pageNumber,
        pageSize: memberSelector?.data?.pageSize,
      }));
    }
  }, [memberSelector])

  return (
    <>
      {(memberSelector?.isLoading === true || getAllProductInGroups?.isLoading === true) && <Loader loaderTransForm="loaderTransForm" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Groups</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: BreadCrum.GROUPS, onClick: () => navigate("/groups") },
              { text: BreadCrum.MANAGE_PRODUCTS, active: true },
            ]}
          />
          <div className="table-padding mb-40 mx850">
            <div className="headtitle d-block">
              <div className="d-flex justify-content-between align-items-center d-4grid">
                <div>
                  <div className="productname mb-10">Group Code </div>
                  <div className="producttitle ">{state?.groupCode || state?.fromEditGroup?.groupCode || state?.groupsDetails?.data?.group?.caspioEmployerGroupCode || "N/A"}</div>
                </div>
                <div className="verticalline mx-100"></div>
                <div className="">
                  <div className="productname mb-10">Group Name</div>
                  <div className="producttitle ">{state?.groupName || state?.groupsDetails?.data?.group?.name || state?.groupsDetails?.data?.group?.state?.groupsDetails?.data?.group?.name || "N/A"}</div>
                </div>
                <div className="verticalline mx-100"></div>
                <div className="">
                  <div className="productname mb-10">Company Name</div>
                  <div className="producttitle ">{state?.companyName || state?.groupsDetails?.data?.companyName || "N/A"}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="switches-container mx-auto mb-30">
            <div
              className="mempro"
              onClick={() => setManageSwitch(false)}
            >
              Members
            </div>
            <div
              className="mempro"
              onClick={() => setManageSwitch(true)}
            >
              Products
            </div>
            <div
              className={
                !manageSwitch ? "switch-wrapper" : "switch-wrapper switchside"
              }
            >
              <div className="switch">
                {!manageSwitch ? (
                  <div>Members</div>
                ) : (
                  <div>Products</div>
                )}
              </div>
            </div>
          </div>
          {!manageSwitch ? (
            <div className="table-padding  mb-40">
              <div className="headtitle">
                <p className="mb-0 heedprodct">Members</p>
                {/* <div className="headtitle d-flex justify-content-between ">
                </div> */}
                <CommanSearchBox
                  value={searchValue}
                  setValue={setSearchValue}
                  getSearchValue={handleMemberListing}
                  placeholder={"Search By User Name"}
                />
              </div>
              <hr className="m-0" />
              <div className="table-responsive overfloauto">
                <CommonTable
                  handlePageChange={handleMemberListing}
                  columns={membersColumns}
                  body={showMembersData}
                  pagination={pagination}
                  className="table usertable w-100"
                />
              </div>
            </div>
          ) : (
            <div className="table-padding ">
              <div className="headtitle">
                <p className="mb-0 heedprodct">Product</p>
              </div>
              <hr className="m-0" />
              <div className="table-responsive overfloauto">
                <CommonTable
                  columns={columns}
                  body={showProductData}
                  className="table usertable w-100"
                />
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ManageMember;
