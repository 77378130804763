import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../common/CommonTable";
import { claimsListColumns } from "./claimUtils";
import { getProductFeatureContentHandler } from "../../redux/action/getProductFeatureContentSlice";
import Loader from "../../common/loader";

const Claim = () => {
  const [handleClaimList, setHandleClaimList] = useState([]);
  // const [searchValue, setSearchValue] = useState("");
  // const { brandId } = useContext(ThemeContext);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 100,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 15, 20],
    className: "order-pagination",
    locale: { items_per_page: "" },
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  });

  const { state } = useLocation();
  const dispatch = useDispatch();
  const getProductFeatureContent = useSelector(
    (state) => state?.getProductFeatureContentMain
  );

  const navigate = useNavigate();
  const dashBoardColumnsData = claimsListColumns(navigate);

  const addClaim = () => {
    navigate("/Add-claim", { state: { fromClaim: state } });
  };

  const getProductId = localStorage.getItem("productId");
  const refFeatureId = localStorage.getItem("refFeatureId");

  const getProductFeatureFunction = () => {
    if (Number(getProductId) && Number(refFeatureId)) {
      let payload = {
        productId: Number(getProductId) || state?.state?.productData?.productId,
        refFeatureId:
          state?.state?.refFeatureId ||
          state?.refFeatureId ||
          Number(refFeatureId),
      };
      dispatch(getProductFeatureContentHandler(payload));
    }
  };

  useEffect(() => {
    getProductFeatureFunction();
  }, []);

  useEffect(() => {
    const claimListData = getProductFeatureContent?.data?.data?.content?.claim?.map(item => ({
      claimName: item?.claimName,
      claimType: item?.claimType,
      language: item?.language,
      urlData: item?.urlData,
      claimStatus: item?.claimStatus?.charAt(0)?.toUpperCase() + item?.claimStatus?.slice(1)?.toLowerCase(),
      id: item?.id,
      productId: getProductFeatureContent?.data?.data?.productId,
      itemStored: item,
    })).reverse();

    setHandleClaimList(claimListData);
  }, [getProductFeatureContent]);

  return (
    <>
      {getProductFeatureContent?.isLoading === true && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>

          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: BreadCrum.PRODUCTS, onClick: () => { navigate("/dashboard"); localStorage.removeItem("productId") } },
              { text: BreadCrum.MANAGE_PRODUCTS, onClick: () => navigate("/edit-product") },
              { text: BreadCrum.CLAIM_LIST, active: true },
            ]}
          />
          <div className="table-padding">
            <div className="headtitle">
              <p className="mb-0 heedprodct">Claims</p>
              <button className="primarybtn" onClick={addClaim}>
                <div className="plus"></div>
                Add
              </button>
            </div>
            <hr className="m-0" />
            <div className="table-responsive">
              <CommonTable
                body={handleClaimList}
                columns={dashBoardColumnsData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Claim;
