import { createSlice } from "@reduxjs/toolkit";
import { getAllUserListAPI } from "../../services/getAllUserList";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const getAllUserListSlice = createSlice({
  name: "getAllUserList",
  initialState: data,
  reducers: {
    getAllUserListInfo(state) {
      state.isLoading = true;
    },
    getAllUserListInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    getAllUserListInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    getAllUserListInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const getAllUserHandler = (data) => async (dispatch) => {
  try {
    dispatch(getAllUserAction.getAllUserListInfo());
    const response = await getAllUserListAPI(data);
    dispatch(getAllUserAction.getAllUserListInfoSuccess(response));
  } catch (e) {
    dispatch(getAllUserAction.getAllUserListInfoFailure(e?.response?.data?.message));
  }
};
export default getAllUserListSlice.reducer;
export const getAllUserAction = getAllUserListSlice.actions;
