import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { pathName } from "../../utils/pathName";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserHandler } from "../../redux/action/getAllUserListSlice";
import { ThemeContext } from "../../common/themeContext";
import Loader from "../../common/loader";
import { usersListColumns } from "./UsersUtils";
import CommonTable from "../../common/CommonTable";
import { BreadCrum } from "../../common";
import { CommanSearchBox } from "../../common/commanSearchBox";

const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = usersListColumns(navigate);
  const { brandId } = useContext(ThemeContext);
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 100,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 15, 20],
    className: "order-pagination",
    locale: { items_per_page: "" },
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  });

  const getUserList = useSelector((state) => state?.getAllUserList);

  const handleUserListData = (
    offSet = 1,
    limit = 10,
    searchString = searchValue
  ) => {
    window.scrollTo(0, 0);
    const payload = { offSet, limit, refBrandId: brandId };
    if (searchString) {
      payload["searchString"] = searchString;
    }
    if (brandId === 1 || brandId === 2) {
      dispatch(getAllUserHandler(payload));
    }
  };

  useEffect(() => {
    handleUserListData();
  }, [brandId]);

  useEffect(() => {
    if (getUserList?.data?.data) {
      const getUser = getUserList?.data?.data.map((item) => {
        const getGroupsData =
          item?.groups?.map((groupItem) => {
            return {
              groupName: groupItem?.groupName,
              groupId: groupItem?.caspioEmployerGroupCode,
              productCode: groupItem?.productCodes
                ?.map((item) => `${item}`)
                .join(", "),
            };
          }) || [];
        return {
          firstName: item?.firstName,
          lastName: item?.lastName,
          emailId: item?.emailId,
          memberId: item?.caspioUserId,
          groupName: getGroupsData.map(item => `${item?.groupName}`).join(", "),
          groupCode: getGroupsData?.map(item => `${item?.groupId}`).join(", "),
          productCode: getGroupsData?.map(item => `${item?.productCode}`).join(", "),
          userId: item?.userId
        };
      });
      setUserData(getUser);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: getUserList?.data?.totalElements,
        current: getUserList?.data?.pageNumber,
        pageSize: getUserList?.data?.pageSize,
      }));
    }
  }, [getUserList]);

  return (
    <>
      {getUserList?.isLoading === true && (
        <Loader loaderTransform="loaderTransform" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>User</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              { text: BreadCrum.USERS, active: true },
            ]}
          />
          <div className="table-padding">
            <div className="headtitle d-flex justify-content-between ">
              <p className="mb-0 heedprodct">Users</p>
              <CommanSearchBox
                value={searchValue}
                setValue={setSearchValue}
                getSearchValue={handleUserListData}
                placeholder={"Search By User Name"}
              />
            </div>
            <hr className="m-0" />
            <div className="table-responsive overfloauto">
              <CommonTable
                handlePageChange={handleUserListData}
                pagination={pagination}
                columns={columns}
                body={userData}
                className="table usertable w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
