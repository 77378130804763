import React from 'react';
import Form from 'react-bootstrap/Form';
import { Checkbox } from 'antd';
import radioimg from '../../assets/radio.svg';
import checkimg from '../../assets/checkbox.svg';
import dropimg from '../../assets/dropdown.svg';
import textimg from '../../assets/textbox.svg';
import ansbtn from '../../assets/ansbtn.svg';
import addbtn from '../../assets/Add_Button.svg';
import copybtn from '../../assets/Copy.svg';
import Bold from '../../assets/Bold.svg';
import Italic from '../../assets/Italic.svg';
import Underline from '../../assets/Underline.svg';
import RemoveFormatting from '../../assets/RemoveFormatting.svg';
import Addlink from '../../assets/Addlink.svg';
import deletebtn from '../../assets/Delete.svg';
import deleteblue from '../../assets/DeleteBlue.svg';
import { Link, useNavigate } from 'react-router-dom';
import SelectInput from '../../common/SelectInput';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';


const Createquestion = () => {
  const navigate = useNavigate();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className="rightside ">
      <div className="rightcontent">
        <p>
          <strong>Products</strong>
        </p>
      
        <CustomBreadcrumb
            items={[
              { text: "Home" },
              { text: "Products", onClick: () => navigate("/dashboard") },
              { text: "Manage Product", onClick: () => navigate("/edit-product") },
              { text: "Claim", onClick: () => navigate("/claim") },
              { text: "Create Questionnaire", active: true },
            ]}
          />
        <Form className="login-form mx-750">
          <div className="table-padding mb-30">
            <div className="headtitle d-block">
              <div className="d-flex justify-content-between align-items-center d-4grid">
                <div>
                  <div className="productname mb-10">Product Name</div>
                  <div className="producttitle ">DPCP</div>
                </div>
                <div className="verticalline mx-100"></div>
                <div className="">
                  <div className="productname mb-10">Product Code</div>
                  <div className="producttitle ">#123</div>
                </div>
                <div className="verticalline mx-100"></div>
                <div className="">
                  <div className="productname mb-10">Product Status</div>
                  <div className="producttitle activetext">Active</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionpadding mb-30">
            <p className="mb-3 heedprodct activetext">Quiz Title</p>
            <p className="mb-0">This will contain description about the quiz</p>
          </div>
          {/* Add Quiz screen */}
          <div className="d-n one">
            <div className="sectionpadding mb-30 hoversection">
              <div className="d-xxl-flex d-block mb-30">
                <Form.Group
                  className=" pe-2 position-relative selecttype me-xxl-2 mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Select Question Type</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: 'Multiple choice',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={radioimg} className="me-2" alt="" />
                            <span>Multiple Choice</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Checkboxes',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={checkimg} className="me-2" alt="" />
                            <span>Checkboxes</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Dropdown',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={dropimg} className="me-2" alt="" />
                            <span>Dropdown</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Textbox',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={textimg} className="me-2" alt="" />
                            <span>Text Box</span>
                          </div>
                        ),
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className=" pe-2 position-relative me-xxl-2 mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>No. of Choices</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: ' 1',
                        label: ' 1',
                      },
                      {
                        value: ' 3',
                        label: ' 3',
                      },
                      {
                        value: ' 2',
                        label: ' 2',
                      },
                    ]}
                    placeholder={'1'}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className=" pe-2 position-relative selecttype mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Enumeration</Form.Label>
                  <SelectInput
                    options={[
                      {
                        value: 'alpha',
                        label: 'A,B,C,D',
                      },
                      {
                        value: 'numeric',
                        label: '1,2,3,4',
                      },
                      {
                        value: 'ALPHA',
                        label: 'a,b,c,d',
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <Form.Group
                className=" pe-2 position-relative mb-30"
                controlId="formBasicEmail"
              >
                <Form.Label>Question </Form.Label>
                <Form.Control
                  className="mb-10 selectdisplayed"
                  type="text"
                  placeholder="Untitled Question"
                  id="textid"
                  name="textid"
                />
                <label className="selectdisplay" for="textid">
                  <button>
                    <img src={Bold} alt="" />
                  </button>
                  <button>
                    <img src={Italic} alt="" />
                  </button>
                  <button>
                    <img src={Underline} alt="" />
                  </button>
                  <button>
                    <img src={Addlink} alt="" />
                  </button>
                  <button>
                    <img src={RemoveFormatting} alt="" />
                  </button>
                </label>
              </Form.Group>

              <Form.Group
                className=" pe-2 position-relative optionselection"
                controlId="formBasicEmail"
              >
                <Form.Label className="mb-20">Options </Form.Label>
                <ul className="p-0">
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 01" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 02" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 03" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 04" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                </ul>
              </Form.Group>
              <hr className="mb-2" />

              <div className="w-100 d-flex justify-content-between iconsizeresponsive">
                <Link className="plusicon anskey">
                  <img src={ansbtn} alt="" /> <span>Answer key</span>
                </Link>
                <div className=" d-flex align-items-center justify-content-start">
                  <Link className="me-20">
                    <img src={addbtn} alt="" />
                  </Link>
                  <Link className="me-20">
                    <img src={copybtn} alt="" />
                  </Link>
                  <Link className="">
                    <img src={deletebtn} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="sectionpadding mb-30 hoversection">
              <div className="d-xxl-flex mb-30">
                <Form.Group
                  className=" pe-2 position-relative selecttype me-xxl-2 mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Select Question Type</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: 'Multiple choice',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={radioimg} className="me-2" alt="" />
                            <span>Multiple Choice</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Checkboxes',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={checkimg} className="me-2" alt="" />
                            <span>Checkboxes</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Dropdown',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={dropimg} className="me-2" alt="" />
                            <span>Dropdown</span>
                          </div>
                        ),
                      },
                      {
                        value: 'Textbox',
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={textimg} className="me-2" alt="" />
                            <span>Text Box</span>
                          </div>
                        ),
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className=" pe-2 position-relative me-xxl-2 mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>No. of Choices</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: ' 1',
                        label: ' 1',
                      },
                      {
                        value: ' 3',
                        label: ' 3',
                      },
                      {
                        value: ' 2',
                        label: ' 2',
                      },
                    ]}
                    placeholder={'1'}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className=" pe-2 position-relative selecttype"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Enumeration</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: 'alpha',
                        label: 'A,B,C,D',
                      },
                      {
                        value: 'numeric',
                        label: '1,2,3,4',
                      },
                      {
                        value: 'ALPHA',
                        label: 'a,b,c,d',
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <Form.Group
                className=" pe-2 position-relative mb-30"
                controlId="formBasicEmail"
              >
                <Form.Label>Question </Form.Label>
                <Form.Control
                  className="mb-10 selectdisplayed"
                  type="text"
                  placeholder="Untitled Question"
                />
                <lable className="selectdisplay" for="formBasicEmail">
                  <button>
                    <img src={Bold} alt="" />
                  </button>
                  <button>
                    <img src={Italic} alt="" />
                  </button>
                  <button>
                    <img src={Underline} alt="" />
                  </button>
                  <button>
                    <img src={Addlink} alt="" />
                  </button>
                  <button>
                    <img src={RemoveFormatting} alt="" />
                  </button>
                </lable>
              </Form.Group>

              <Form.Group
                className=" pe-2 position-relative optionselection"
                controlId="formBasicEmail"
              >
                <Form.Label className="mb-20">Options </Form.Label>
                <ul className="p-0">
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 01" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 02" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 03" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 04" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                </ul>
              </Form.Group>
              <hr className="mb-2" />

              <div className="w-100 d-flex justify-content-between iconsizeresponsive">
                <Link className="plusicon anskey">
                  <img src={ansbtn} alt="" /> <span>Answer key</span>
                </Link>
                <div className=" d-flex align-items-center justify-content-start">
                  <Link className="me-20">
                    <img src={addbtn} alt="" />
                  </Link>
                  <Link className="me-20">
                    <img src={copybtn} alt="" />
                  </Link>
                  <Link className="">
                    <img src={deletebtn} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Ans Selection screen */}
          <div className="d-n one">
            <div className="sectionpadding mb-30 hoversection">
              <p className="mb-3 heedprodct ">Select Correct Answer</p>
              <hr />

              <Form.Group
                className=" pe-2 position-relative optionselection"
                controlId="formBasicEmail"
              >
                <Form.Label className="mb-20">
                  What is the full form of USA?
                </Form.Label>
                <ul className="p-0">
                  <li>
                    <input type="radio" id="chec1" name="chec1" value="chec1" />
                    <label for="chec1">
                      <div className="checkradiobtn"></div> Option 01
                    </label>
                    {/* <Checkbox>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center">
                              <div className="checkradiobtn"></div> Option 01
                            </div>
                            <img src={chekbtn} alt="" />
                          </div>
                        </Checkbox> */}
                  </li>
                  <li>
                    <input type="radio" id="chec2" name="chec1" value="chec2" />
                    <label for="chec2">
                      <div className="checkradiobtn"></div> Option 02
                    </label>
                  </li>
                  <li>
                    <input type="radio" id="chec3" name="chec1" value="chec3" />
                    <label for="chec3">
                      <div className="checkradiobtn"></div>Option 03
                    </label>
                  </li>
                  <li>
                    <input type="radio" id="chec4" name="chec1" value="chec4" />
                    <label for="chec4">
                      <div className="checkradiobtn"></div> Option 04
                    </label>
                  </li>
                </ul>
              </Form.Group>
              <div className="text-end">
                <Link
                  role="button"
                  tabindex="0"
                  type="submit"
                  className=" sbg border-0  fw-18 gbo continuebtn savebtn  "
                >
                  Done
                </Link>
              </div>
            </div>
            <div className="sectionpadding mb-30 hoversection">
              <Form.Group
                className=" pe-2 position-relative optionselection"
                controlId="formBasicEmail"
              >
                <Form.Label className="mb-20">
                  What is the full form of USA?
                </Form.Label>
                {/* <ul className="p-0">
                      <li>
                        <input
                          type="checkbox"
                          id="check15"
                          name="check15"
                          value="check15"
                        />
                        <label for="check15">
                          <div className="checkradiobtn"></div>
                          <div className="d-flex align-items-center justify-content-between w-cal">
                            <input type="text" placeholder="Option 01" />
                            <img src={deleteblue} alt="" />
                          </div>
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          id="check25"
                          name="check25"
                          value="check25"
                        />
                        <label for="check25">
                          <div className="checkradiobtn"></div>
                          <div className="d-flex align-items-center justify-content-between w-cal">
                            <input type="text" placeholder="Option 02" />
                            <img src={deleteblue} alt="" />
                          </div>
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          id="check35"
                          name="check35"
                          value="check35"
                        />
                        <label for="check35">
                          <div className="checkradiobtn"></div>
                          <div className="d-flex align-items-center justify-content-between w-cal">
                            <input type="text" placeholder="Option 03" />
                            <img src={deleteblue} alt="" />
                          </div>
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          id="check45"
                          name="check45"
                          value="check45"
                        />
                        <label for="check45">
                          <div className="checkradiobtn"></div>
                          <div className="d-flex align-items-center justify-content-between w-cal">
                            <input type="text" placeholder="Option 04" />
                            <img src={deleteblue} alt="" />
                          </div>
                        </label>
                      </li>
                    </ul> */}
                <ul className="p-0">
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 01" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 02" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 03" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 04" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                </ul>
              </Form.Group>

              <div className="w-100 d-flex justify-content-between iconsizeresponsive">
                <Link className="plusicon anskey">
                  <img src={ansbtn} alt="" /> <span>Answer key</span>
                </Link>
                <div className=" d-flex align-items-center justify-content-start">
                  <Link className="me-20">
                    <img src={addbtn} alt="" />
                  </Link>
                  <Link className="me-20">
                    <img src={copybtn} alt="" />
                  </Link>
                  <Link className="">
                    <img src={deletebtn} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Selected Ans screen */}
          <div>
            <div className="sectionpadding mb-30 hoversection">
              <div className="d-xxl-flex mb-30">
                <Form.Group
                  className=" pe-2 position-relative selecttype me-xxl-2 mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Select Question Type</Form.Label>
                  <SelectInput
                    options={[
                      {
                        value: "Multiple choice",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={radioimg} className="me-2" alt="" />
                            <span>Multiple Choice</span>
                          </div>
                        ),
                      },
                      {
                        value: "Checkboxes",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={checkimg} className="me-2" alt="" />
                            <span>Checkboxes</span>
                          </div>
                        ),
                      },
                      {
                        value: "Dropdown",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={dropimg} className="me-2" alt="" />
                            <span>Dropdown</span>
                          </div>
                        ),
                      },
                      {
                        value: "Textbox",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={textimg} className="me-2" alt="" />
                            <span>Text Box</span>
                          </div>
                        ),
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className=" pe-2 position-relative me-xxl-2 mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>No. of Choices</Form.Label>

                  <SelectInput options={[
                    {
                      value: " 1",
                      label: " 1",
                    },
                    {
                      value: " 3",
                      label: " 3",
                    },
                    {
                      value: " 2",
                      label: " 2",
                    },
                  ]} placeholder={'1'} onChange={handleChange} />
                </Form.Group>
                <Form.Group
                  className=" pe-2 position-relative selecttype mb-3 mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Enumeration</Form.Label>

                  <SelectInput options={[
                    {
                      value: "alpha",
                      label: "A,B,C,D",
                    },
                    {
                      value: "numeric",
                      label: "1,2,3,4",
                    },
                    {
                      value: "ALPHA",
                      label: "a,b,c,d",
                    },
                  ]} placeholder={'Select Question Type'} onChange={handleChange} />
                </Form.Group>
              </div>
              <Form.Group
                className=" pe-2 position-relative mb-30"
                controlId="formBasicEmail"
              >
                <Form.Label>Question </Form.Label>
                <Form.Control
                  className="mb-10 selectdisplayed"
                  type="text"
                  placeholder="Untitled Question"
                />
                <div className="selectdisplay">
                  <button>
                    <img src={Bold} alt="" />
                  </button>
                  <button>
                    <img src={Italic} alt="" />
                  </button>
                  <button>
                    <img src={Underline} alt="" />
                  </button>
                  <button>
                    <img src={Addlink} alt="" />
                  </button>
                  <button>
                    <img src={RemoveFormatting} alt="" />
                  </button>
                </div>
              </Form.Group>

              <Form.Group
                className=" pe-2 position-relative optionselection"
                controlId="formBasicEmail"
              >
                <Form.Label className="mb-20">Options </Form.Label>

                <ul className="p-0">
                  <li>
                    <Checkbox className="ansSelected">
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 01" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 02" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 03" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                  <li>
                    <Checkbox>
                      <div className="checkradiobtn d-inline-block"></div>
                      <div className="d-flex align-items-center justify-content-between w-cal">
                        <input type="text" placeholder="Option 04" />
                        <img src={deleteblue} alt="" />
                      </div>
                    </Checkbox>
                  </li>
                </ul>
              </Form.Group>
              <hr className="mb-2" />

              <div className="w-100 d-flex justify-content-between iconsizeresponsive">
                <div className=" d-flex align-items-center justify-content-between justify-content-md-start">
                  <Link className="plusicon anskey mx-0">
                    <img src={ansbtn} alt="" /> <span>Answer key</span>
                  </Link>
                  <div className="optionselected">(Option 01)</div>
                </div>
                <div className=" d-flex align-items-center justify-content-start">
                  <Link className="me-20">
                    <img src={addbtn} alt="" />
                  </Link>
                  <Link className="me-20">
                    <img src={copybtn} alt="" />
                  </Link>
                  <Link className="">
                    <img src={deletebtn} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Form Text box */}
          <div>
            <div className="sectionpadding mb-30 hoversection">
              <div className="d-flex mb-30">
                <Form.Group
                  className=" pe-2 position-relative selecttype  mb-xxl-0"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Select Question Type</Form.Label>

                  <SelectInput
                    options={[
                      {
                        value: "Multiple choice",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={radioimg} className="me-2" alt="" />
                            <span>Multiple Choice</span>
                          </div>
                        ),
                      },
                      {
                        value: "Checkboxes",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={checkimg} className="me-2" alt="" />
                            <span>Checkboxes</span>
                          </div>
                        ),
                      },
                      {
                        value: "Dropdown",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={dropimg} className="me-2" alt="" />
                            <span>Dropdown</span>
                          </div>
                        ),
                      },
                      {
                        value: "Textbox",
                        label: (
                          <div className="d-flex align-items-center">
                            <img src={textimg} className="me-2" alt="" />
                            <span>Text Box</span>
                          </div>
                        ),
                      },
                    ]}
                    placeholder={'Select Question Type'}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <Form.Group
                className=" pe-2 position-relative mb-30"
                controlId="formBasicEmail"
              >
                <Form.Label>Question </Form.Label>
                <Form.Control
                  className="mb-10 selectdisplayed"
                  type="text"
                  placeholder="Untitled Question"
                />
                <div className="selectdisplay">
                  <button>
                    <img src={Bold} alt="" />
                  </button>
                  <button>
                    <img src={Italic} alt="" />
                  </button>
                  <button>
                    <img src={Underline} alt="" />
                  </button>
                  <button>
                    <img src={Addlink} alt="" />
                  </button>
                  <button>
                    <img src={RemoveFormatting} alt="" />
                  </button>
                </div>
              </Form.Group>

              <Form.Group
                className=" pe-2 position-relative optionselection"
                controlId="formBasicEmail"
              >
                <Form.Label className="mb-20">Answer </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Type in your answer here..."
                />
              </Form.Group>
              <hr className="mb-2" />

              <div className="w-100 d-flex justify-content-between iconsizeresponsive">
                <Link className="plusicon anskey">
                  <img src={ansbtn} alt="" /> <span>Answer key</span>
                </Link>
                <div className=" d-flex align-items-center justify-content-start">
                  <Link className="me-20">
                    <img src={addbtn} alt="" />
                  </Link>
                  <Link className="me-20">
                    <img src={copybtn} alt="" />
                  </Link>
                  <Link className="">
                    <img src={deletebtn} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="text-end">
          <Link
            role="button"
            tabindex="0"
            to="/addproduct"
            type="submit"
            className=" sbg border-0  fw-18 gbo continuebtn savebtn  "
          >
            Save
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Createquestion;
