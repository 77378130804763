
const downloadCSVFile = (base64String, filename) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'text/csv;charset=utf-8;' });

  // Create a link element and trigger the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const reportColumns = (navigate) => [
  {
    title: 'Report Name',
    dataIndex: 'reportName',
    key: "reportName",
  },
  
      {
        title: 'Action',
        key: "action",
        render: (key) => {
          return (
            <div className="action-icons">
              <div
              >
                <button className="downloadReports" onClick={() => downloadCSVFile(key?.fileData, 'download.csv')}>
                  Download
                </button>
              </div>
            </div>
          );
        },
      },
];

