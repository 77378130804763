import { Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import PrivateRoute from "./PrivateRoute";
import { ThemeContext } from "../common/themeContext";
import Sidebar from "../common/sidebar";
import { CommonMessageProvider } from "../common/CommonMessage";

const AppRoutes = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={theme === "healthCare" ? "healthcare" : "primecare"}>
      <CommonMessageProvider>
        <Suspense>
          <Routes>
            {routes.map((route, index) => (
              <Route
                path={route?.path}
                key={"route_" + index}
                element={
                  route.isPrivate ? (
                    <>
                      <Sidebar>
                        <PrivateRoute redirectPath="/">
                          <route.element />
                        </PrivateRoute>
                      </Sidebar>
                    </>
                  ) : route?.isLayout ? (
                    <Sidebar>
                      <route.element />
                    </Sidebar>
                  ) : (
                    <route.element />
                  )
                }
              />
            ))}
          </Routes>
        </Suspense>
      </CommonMessageProvider>
    </div>
  );
};

export default AppRoutes;
