export const handleKeyPressSpace = (event) => {
  // Prevent typing a space at the beginning
  if (event.key === ' ' && event.target.selectionStart === 0) {
    event.preventDefault();
  }
};
// Handle Product Code validation
export const handleKeyPress = (event) => {
  const regex = /^[a-zA-Z0-9-_]*$/;
  if (!regex.test(event.key)) {
    event.preventDefault();
  }
};

// Handle Copy Paste functionality for blank space

export const handlePaste = (event) => {
  // Get the clipboard data
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');

  // Check if the pasted data contains only whitespace
  if (!pastedData.trim()) {
    event.preventDefault();
  }
};

export const handleInputChange = (e) => {
  const { value, maxLength } = e.target;
  if (value.length > maxLength) {
    e.target.value = value.slice(0, maxLength);
  }
};


// Handle up and down arrow

export const handleKeyDown = (e) => {
  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();
  }
}