import { Input } from "antd";
import { useCallback, useMemo } from "react";
import SearchIcon from "../assets/search-normal.svg"
export const CommanSearchBox = ({
  value,
  setValue,
  getSearchValue,
  userRecord,
  isPagination = true,
  placeholder = "",
}) => {
  const debounce = (func, wait) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, wait);
    };
  };

  const sendRequest = useCallback((value) => {
    if (isPagination) {
      getSearchValue(1, 5, value);
    } else {
      getSearchValue(userRecord, value);
    }
  }, [getSearchValue, isPagination, userRecord]);
  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 800);
  }, [sendRequest]);

  const handleOnChange = (event) => {
    const { value } = event.target;
    setValue(value.trim());
    debouncedSendRequest(value.trim());
  };
  return (
    <Input
      className="table-search-input form-control w-50"
      placeholder={`Search ${placeholder}`}
      prefix={
        <img
          src={SearchIcon}
          height={"24px"}
          width={"24px"}
          alt="Search Icon"
        />
      }
      value={value}
      onChange={handleOnChange}
    />
  );
};
